<template>
  <div class="container">
    <!-- HIDDEN-FEATURE -->
    <div class="in-progress">
      <svg fill="#4ea934" width="200px" height="200px" viewBox="0 -8 72 72"><polygon points="52.7 39.8 19.61 39.8 19.61 33.91 11.91 33.91 11.91 51.6 19.61 51.6 19.61 45.6 52.7 45.6 52.7 52 60.39 52 60.39 34.3 52.7 34.3 52.7 39.8"/><polygon points="33.3 12.01 23.21 12.01 11.13 31.21 21.22 31.21 33.3 12.01"/><polygon points="35.22 31.21 47.3 12.01 38.18 12.01 26.1 31.21 35.22 31.21"/><path d="M66.51,12,54.43,31.21H66.16c1.86,0,2.23-1,2.23-2.5V12Z"/><polygon points="49.55 31.21 61.63 12.01 52.18 12.01 40.1 31.21 49.55 31.21"/><path d="M5.64,12s-2-.3-2,2.17v17H6.25L18.33,12Z"/></svg>
      <h3 class="title">Atualizações em Progresso<br><span style="font-size: 14px; color: inherit; margin-top: 8px; opacity: 0.7;">Estamos dedicados a melhorar sua experiência.<br>Em breve, traremos mais novidades!</span></h3>
    </div>
    <!-- HIDDEN-FEATURE -->
    <div class="container-company mt-4" v-if="!hide" @mouseover="hide = true">
      <div class="logo">
        <img :src="require('@/assets/img/greenn_company.png')" class="logo" />
      </div>
      <div class="container-companys">
        <div class="company">
          <div
            class="card-company"
            @click="redirect('greenn')"
            @mouseover="showMessage('greenn', 'greenn')"
            @mouseleave="hideMessage"
          >
            <img
              :src="require('@/assets/img/greenn.png')"
              class="company-logo"
            />
          </div>
          <h1 class="company-title">Greenn Adm</h1>
        </div>
        <div class="company">
          <div
            class="card-company"
            @click="redirect('club')"
            @mouseover="showMessage('club', 'club')"
            @mouseleave="hideMessage"
          >
            <img :src="require('@/assets/img/club.png')" class="company-logo" />
          </div>
          <h1 class="company-title">Greenn Club</h1>
        </div>
        <div class="company">
          <div
            class="card-company"
            @click="redirect('gdigital')"
            @mouseover="showMessage('g-digital', 'G-digital')"
            @mouseleave="hideMessage"
            style="background: #f7f7f7"
          >
            <img
              :src="require('@/assets/img/G-digital.png')"
              class="company-logo"
            />
          </div>
          <h1
            class="company-title"
            style="color: var(--gray01); font-weight: 600"
          >
            G Digital
          </h1>
          <div class="card-point">
            <h1 class="validDrag">Você está aqui</h1>
          </div>
        </div>
        <div class="company" v-if="heaven">
          <div
            class="card-company"
            @click="redirect('heaven')"
            @mouseover="showMessage('heaven', 'heaven')"
            @mouseleave="hideMessage"
          >
            <img
              :src="require('@/assets/img/heaven.png')"
              class="company-logo"
            />
          </div>
          <h1 class="company-title">Heaven Payment</h1>
        </div>
      </div>
      <div class="render-company">
        <div class="render-img">
          <img :src="imgCompany" class="logo" />
        </div>
        <div class="render-text">
          <p>{{ textCompany }}</p>
        </div>
      </div>
    </div>
    <CreatePassword :company_selected="company_selected" />
  
</div>
</template>

<script>
import Vue from "vue";

//
import UserService from "@/services/resources/UsersService.js";
const serviceUser = UserService.build();
//
import CreatePassword from "./CompanyCreatePassword.vue";
//

import GlobalSettingsService from "@/services/resources/GlobalSettingsService";
const serviceGlobalSettings = GlobalSettingsService.build();

export default {
  components: { CreatePassword },
  
  data() {
    return {
      // HIDDEN-FEATURE
      hide: false,
      greenn: true,
      heaven: false,
      club_has: false,
      company_selected: "g-digital",
      imgCompany: require("@/assets/img/G-digital.png"),
      textCompany:
        "Plataforma de funil de vendas que simplifica seu marketing, automatiza, impulsiona suas vendas e te permite gerir negócios com excelência.",
    };
  },
  created(){
    if(this.$store.getters.user.user.level != "owner" ){
      this.$router.push({name: "Dashboard"});
      return;
    }
    
    this.canIntegrate();
  },  
  mounted(){
    this.getUser();
  },  
  methods: {
    canIntegrate(){
      serviceGlobalSettings
      .read({id: "company_users"})
      .then((resp) => {
        let users = JSON.parse(resp.meta_value) ?? [];
        let can_integrate = users.includes(this.$store.getters.user.user.id);
        if(!can_integrate){
          this.$router.push({name: "Dashboard"});
        }
      }).catch((err) => {
        this.$router.push({name: "Dashboard"});
      })
    },
    getUser(){
      var data = {
        id: "/login-integration-user",
      };
      
      serviceUser
        .createId(data)
        .then((resp) => {
          this.heaven = resp.is_heaven;
          this.greenn = resp.is_greenn;
          this.club_has = resp.club_has;
        }).catch((error) => {
          // console.log(error);
          this.greenn = true;
          this.club_has = true;
        })
    },
    redirect(company) {
      if (company === "gdigital") {
        this.$bvToast.toast("Você ja está aqui", {
          title: "Atenção",
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
        return;
      }
      if(company === "club" && !this.club_has){
        this.$bvToast.toast("Você ainda não possui integração com o Club.", {
          title: "Atenção",
          variant: "info",
          autoHideDelay: 5000,
          appendToast: true,
        });
        return;
      }
      this.company_selected = company;
      const companyUrls = {
        greenn: process.env.VUE_FRONT_GREENN,
        club: process.env.VUE_FRONT_CLUB,
        gdigital: process.env.VUE_FRONT_GDIGITAL,
        heaven: process.env.VUE_FRONT_HEAVEN,
      };
      var data = {
        id: "/login-integration",
        to: company,
      };
      if (data.to == "heaven") {
        data.to = "greenn";
      }
      serviceUser
        .createId(data)
        .then((resp) => {
          // Se não existir Token -->>
          if (resp.ck == "false" || resp.ck == false) {
            // greenn -->
            if (company === "greenn") {
              this.$bvToast.toast(
                "Não possui conta , crie uma senha para acessar",
                {
                  title: "Integrações",
                  variant: "danger",
                  autoHideDelay: 5000,
                  appendToast: true,
                }
              );
              this.$bvModal.show("create-password");
            }
            // club -->
            else if (company === "club") {
              Vue.swal({
                title: "Acessar o GreenClub",
                text: `Para acessar, primeiro é necessário ter uma conta na Greenn e associar um produto ao Club.`,
                type: "danger",
                showCancelButton: true,
                confirmButtonText: "Entrar na Greenn",
                cancelButtonText: "Cancelar",
                customClass: "sweet-container",
                confirmButtonClass: "button button-black mt-3 mb-3",
                cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
                buttonsStyling: false,
                reverseButtons: true,
              }).then((result) => {
                if (result.isConfirmed) {
                  this.redirect("greenn");
                }
              });
            }
            // heaven -->
            if (company === "heaven") {
              this.$bvToast.toast(
                "Não possui conta, crie uma senha para acessar",
                {
                  title: "Integrações",
                  variant: "danger",
                  autoHideDelay: 5000,
                  appendToast: true,
                }
              );
              this.$bvModal.show("create-password");
            }
          }
          // Se existir Token -->>
          else {
            // club -->
            if (company === "club") {
              window.open(`${companyUrls[company]}?rdr=${resp.ck}&gd=true`, "_blank");
            }
            // greenn / g-digital / heaven -->
            else {
              window.open(`${companyUrls[company]}?rdr=${resp.ck}&gd=true`, "_blank");
            }
          }
        })
        .catch((error) => {
          // console.log(error);
          this.$bvToast.toast("Erro ao verificar conta", {
            title: "Integrações",
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true,
          });
        })
        .finally(() => {});
    },
    showMessage(company, image) {
      var all_company = {
        greenn:
          "Plataforma de pagamentos com performance em vendas: Simples, Segura e Humanizada.",
        club: "A área de membros que oferece aos seus alunos a porta de entrada para uma experiência de aprendizagem envolvente e inesquecível.",
        "g-digital":
          "Plataforma de funil de vendas que simplifica seu marketing, automatiza, impulsiona suas vendas e te permite gerir negócios com excelência.",

        heaven:
          "Plataforma de pagamento internacional e multi moeda. Uma solução de pagamento simples em todo o mundo.",
      };
      this.textCompany = all_company[company];
      this.imgCompany = require(`@/assets/img/${image}.png`);
    },
    hideMessage() {
      this.textCompany =
        "Plataforma de funil de vendas que simplifica seu marketing, automatiza, impulsiona suas vendas e te permite gerir negócios com excelência.";
      this.imgCompany = require("@/assets/img/G-digital.png");
    },
  },
};
</script>

<style lang="scss" scoped>
// HIDDEN-FEATURE
.in-progress {
  width: calc(100vw - 100px);
  max-width: 100%;
  height: 100%;
  display: grid;
  background-color: #fff5;
  backdrop-filter: blur(10px);
  align-content: center;
  justify-items: center;
  position: absolute;
  z-index: 10;
  left: 100px;
  top: 0;
  animation: slideIn .5s ease-in-out forwards;
  h3 {
    text-align: center;
    font-size: 18px;
  }
}
.render-company {
  width: 1125px;
  height: 260px;
  display: grid;
  gap: 15px;
  padding: 45px;
  background: #f7f7f7;
  border-radius: 10px;
  transition: all 0.3s ease;
  animation: slideIn .5s ease-in-out forwards;
  animation-delay: 0.5s;
  opacity: 0;
  // justify-content: start;
  .render-text {
    p {
      font-size: 16px;
      color: var(--gray01);
      width: 99%;
    }
  }
  .render-img {
    display: grid;
    justify-content: start;
    img {
      width: 80px;
    }
  }
}
.render-company:hover {
  opacity: 0.8;
}
.company-logo {
  width: 155px;
  height: 155px;
  object-fit: contain;
  transition: opacity 0.3s ease;
}
.company:hover .company-logo {
  opacity: 0.8;
}
.company-logo {
  width: 155px;
  height: 155px;
  // width: 10vw;
  // height: 150px;
  object-fit: contain;
}
.dropdown-toggle {
  cursor: pointer;
}
.card-point {
  position: absolute;
  bottom: 280px;
  left: 0;
  h1 {
    background: rgba(0, 151, 42, 0.2);
    border-radius: 10px;
    color: #00972a;
    font-size: 14px;
    font-weight: 600;
    padding: 5px;
  }
}
.company-title {
  position: relative;
  top: 1rem;
  font-size: 18px;
  color: #81858e;
}
.card-company {
  // width: 250px;
  display: grid;
  justify-content: center;
  border: 1px solid #e7e7e7;
  border-radius: 10px;
  padding: 45px;
  cursor: pointer;
  transition: all 0.2s;
  &:hover {
    background: #f7f7f780;
  }
}
.company {
  text-align: center;
  position: relative;
  opacity: 0;
}
.company:nth-child(1) {
  // animation: slideIn 0.4s ease-in-out;
  animation: slideIn .5s ease-in-out forwards;
  animation-delay: 0;
}
.company:nth-child(2) {
  // animation: slideIn 0.6s ease-in-out;
  animation: slideIn .5s ease-in-out forwards;
  animation-delay: 0.1s;
}
.company:nth-child(3) {
  // animation: slideIn 0.8s ease-in-out;
  animation: slideIn .5s ease-in-out forwards;
  animation-delay: 0.2s;
}
.company:nth-child(4) {
  // animation: slideIn 1s ease-in-out;
  animation: slideIn .5s ease-in-out forwards;
  animation-delay: 0.3s;
}
.container-companys {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 45px;
}
.logo {
  margin: 0 auto;
  width: 200px;
  opacity: 0;
  animation-name: fade;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.container-company {
  display: grid;
  align-items: center;
  justify-content: center;
  gap: 45px;
}

@keyframes slideIn {
  0% {
    opacity: 0;
    // transform: translateX(100%);
  }
  100% {
    opacity: 1;
    // transform: translateX(0);
  }
}

@media (min-width: 1280px) {
  .card-point {
    bottom: 190px;
    h1 {
      font-size: 12px;
    }
  }
  .render-company {
    // width: 780px;
    // height: 280px;
    .render-img img {
      width: 70px;
    }
  }
  .company-logo {
    width: 8vw;
    height: 100px;
  }
  .card-company {
    padding: 30px;
  }
}
</style>
